import React from "react";
import styled from "styled-components";
import RestaurantIcon from "@mui/icons-material/Restaurant";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import Vision from "../assets/vision.webp"
import Team from "../assets/team.webp"

const Styles = styled.div`
  .about-desc h1 {
    font-size: 34px;
    font-weight: 700;
  }
  .about-desc h2 {
    color: #303030;
    font-weight: 300;
    margin-bottom: 20px;
    font-size: 23px;
  }
  .about-desc p {
    line-height: 40px;
    font-size: 18px;
    margin-top: 23px;
  }
  .herero-image {
    margin-top: 5rem !important;
  }
  img {
    width: 75%;
    position: relative;
    display: block;
    z-index: 1;
    margin: auto;
    border: 7px solid #fff;
    z-index: 0 !important;
  }
  .about-desc {
    padding: 30px;
  }
  .card {
    max-width: 100%;
  }
`;

export const About = () => (
  <Styles>
    <div className="card mb-3">
      <div className="row g-0 ">
        <div className="col-md-3 mt-5 herero-image">
          <img
            className="img-responsive"
            src={Vision}
            alt="Gesunde Rezepte"
          />
          <div className="text-center">
          <a className="text-center" style={{fontSize:"10px"}} href="https://www.freepik.com/free-ai-image/world-health-day-celebration-with-healthy-food_138542846.htm">Image by freepik</a>
            <h5 className="card-title">Gesunde Rezepte</h5>
            <h6 className="card-subtitle mb-2 text-muted">Ihre Quelle für ein gesünderes Leben</h6>
            <div className="icon-wrapper">
              <RestaurantIcon fontSize="large" />
              <FiberManualRecordIcon fontSize="large" />
            </div>
          </div>
        </div>
        <div className="col-md-8">
          <div className="card-body">
            <div className="about-desc">
              <h2>Unsere Vision</h2>
              <p className="card-text text-justify">
                Wir streben danach, Menschen zu inspirieren, gesünder zu essen und zu leben. Unsere Vision ist eine Welt, in der jeder Zugang zu einfachen, schmackhaften und nahrhaften Rezepten hat, die das Wohlbefinden fördern und die Freude am Essen steigern.
              </p>
              <h2>Unsere Mission</h2>
              <p className="card-text text-justify">
                Unsere Mission ist es, eine umfangreiche Sammlung von gesunden Rezepten bereitzustellen, die für jeden zugänglich und umsetzbar sind. Wir möchten kreative und abwechslungsreiche Rezepte anbieten, Informationen über ausgewogene Ernährung vermitteln und eine unterstützende Community aufbauen.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="card mb-3">
      <div className="row g-0 ">
        <div className="col-md-3 mt-5">
          <img
            className="img-responsive z-0"
            src={Team}
            alt="Unser Team"
          />
          <div className="text-center">
          <a className="text-center" style={{fontSize:"10px"}} href="https://www.freepik.com/free-photo/camera-filming-people-cooking-professional-menu-dish-kitchen-recording-online-vlog-gastronomy-show-program-tv-team-cooks-teaching-gourmet-meal-preparations-tripod-shot_31084905.htm#fromView=search&page=1&position=18&uuid=70963b32-8598-4095-bb6c-28d3077ddc02">Image by DC Studio on Freepik</a>
            <h5 className="card-title">Unser Team</h5>
            <h6 className="card-subtitle mb-2 text-muted">Leidenschaftliche Köche & Ernährungsexperten</h6>
            <div className="icon-wrapper">
              <RestaurantIcon fontSize="large" />
              <FiberManualRecordIcon fontSize="large" />
            </div>
          </div>
        </div>
        <div className="col-md-8">
          <div className="card-body">
            <div className="about-desc">
              <p className="card-text text-justify">
                Unser Team besteht aus leidenschaftlichen Köchen, Ernährungsexperten und Gesundheitsenthusiasten. Gemeinsam arbeiten wir daran, Ihnen die besten und gesündesten Rezepte zu bieten. Wir glauben, dass gesunde Ernährung nicht nur gut für den Körper ist, sondern auch Freude bereiten und den Geist nähren kann. Mit unserer Expertise und Kreativität möchten wir Sie auf Ihrem Weg zu einem gesünderen Lebensstil begleiten und inspirieren.
              </p>
              <p className="card-text text-justify">
                Entdecken Sie mit uns die Vielfalt der gesunden Küche, lernen Sie neue Zutaten kennen und finden Sie Rezepte, die nicht nur nahrhaft, sondern auch köstlich sind. Lassen Sie uns gemeinsam den Weg zu einem ausgewogenen und genussvollen Leben gehen!
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Styles>
);