import React, { useEffect, useState } from "react";
import { CircularProgress, Grid, styled } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActionArea } from "@mui/material";
import { useNavigate } from "react-router-dom";

const ProductName = styled((props) => (
  <Typography variant="body" component="p" {...props} />
))(({ theme }) => ({
  fontWeight: "bold",
  marginBottom: "0.5rem",
  fontSize: "calc(16px)",
}));

export default function Blog() {
  const [posts, setPosts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    fetchPosts();
  }, []);

  const fetchPosts = async function () {
    try {
      const url = process.env.REACT_APP_URL;
      const response = await fetch(`${url}/create-article`);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setPosts(data);
    } catch (error) {
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return (
      <div style={{ textAlign: "center", marginTop: "50px" }}>
        <CircularProgress />
        <div>Loading posts, please wait...</div>
      </div>
    );
  }

  if (error) {
    return (
      <div style={{ textAlign: "center", marginTop: "50px" }}>
        <Typography color="error">Error: {error}</Typography>
      </div>
    );
  }

  const handleClick = (id) => {
    navigate(`/article/${id}`);
  };

  return (
    <Grid container spacing={2} maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      {posts?.map((post, index) => (
        <Grid item xs={12} sm={6} md={4} lg={3} key={post._id}>
          <Card
            sx={{
              maxWidth: 350,
              width: '100%',
              height: "100%",
              backgroundColor: index % 2 === 0 ? "#f0f0f0" : "#ffffff",
            }}
            onClick={() => handleClick(post._id)}
          >
            <CardActionArea sx={{ height: "100%" }}>
              <CardMedia
                component="img"
                image={post.cover}
                alt={post.title}
                sx={{
                  height: 140,
                  width: '100%',
                  position: 'absolute',
                  top: 0,
                  left: 0,
                }}
              />
              <CardContent sx={{ paddingTop: '140px' }}>
                <ProductName>{post.title}</ProductName>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
}
