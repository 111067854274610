import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

const Styles = styled.div`
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
width: 100%;
height: auto;
`
export const NoMatch = () => {

  const navigate = useNavigate()

  useEffect(() => {
    const redirectTimer = setTimeout(() => {
      navigate('/')
    }, 5000)
    return () => {
      clearTimeout(redirectTimer);
    };
  }, [navigate])

  return (
    <Styles >

      <h2> 404 Page not found</h2>

      <p>You will be redirected to the home page in 5 seconds...</p>


    </Styles>
  );
}