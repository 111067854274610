import React, { useEffect, useState } from "react";
import { CircularProgress, Container, Grid, styled } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActionArea } from "@mui/material";
import { useNavigate } from "react-router-dom";

const ProductName = styled((props) => (
  <Typography variant="body" component="p" {...props} />
))(({ theme }) => ({
  fontWeight: "bold",
  marginBottom: "0.5rem",
  fontSize: "calc(16px)",
}));

export default function CategoryPostsPage() {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [categorizedPosts, setCategorizedPosts] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const url = process.env.REACT_APP_URL;
        const response = await fetch(`${url}/post`);
        if (!response.ok) {
          throw new Error("Failed to fetch posts.");
        }
        const data = await response.json();
        categorizePosts(data);
      } catch (error) {
        setError(error.message);
      } finally {
        setIsLoading(false);
      }
    };
    fetchPosts();
  }, []);

  const categorizePosts = (posts) => {
    const categories = {};

    posts.forEach((post) => {
      let postCategories = post.category;
      if (typeof postCategories === "string") {
        postCategories = postCategories.split(",");
      }
      postCategories.forEach((category) => {
        if (!categories[category]) {
          categories[category] = [];
        }
        categories[category].push(post);
      });
    });

    setCategorizedPosts(categories);
  };

  const handleClick = (id) => {
    navigate(`/post/${id}`);
  };

  if (isLoading) {
    return (
      <div style={{ textAlign: "center", marginTop: "50px" }}>
        <CircularProgress />
        <div>Loading posts, please wait...</div>
      </div>
    );
  }

  if (error) {
    return (
      <div style={{ textAlign: "center", marginTop: "50px" }}>
        <Typography color="error">Error: {error}</Typography>
      </div>
    );
  }

  return (
    <Container maxWidth="lg" sx={{paddingLeft:"-15px"}}>
      <h1>Alle Rezepte</h1>
      {Object.keys(categorizedPosts).map((category) => (
        <div key={category}>
          <Typography variant="h4" component="h2" sx={{ marginTop: 4, marginBottom: 2 }}>
            {category}
          </Typography>
          <Grid container spacing={2} maxWidth="lg" sx={{ mb: 4 }}>
            {categorizedPosts[category].map((post, index) => (
              <Grid item xs={12} sm={6} md={4} lg={3} key={post._id}>
                <Card
                  sx={{
                    maxWidth: 350,
                    width: '100%',
                    height: "100%",
                    backgroundColor: index % 2 === 0 ? "#f0f0f0" : "#ffffff",
                  }}
                  onClick={() => handleClick(post._id)}
                >
                  <CardActionArea sx={{ height: "100%", display: 'flex', flexDirection: 'column' }}>
                    <CardMedia
                      component="img"
                      image={post.cover}
                      alt={post.title || "Post cover"}
                      sx={{
                        height: 140,
                        width: '100%',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                      }}
                    />
                    <CardContent sx={{ paddingTop: '140px' }}>
                      <ProductName>{post.title}</ProductName>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
            ))}
          </Grid>
        </div>
      ))}
    </Container>
  );
}