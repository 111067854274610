import React, { useContext } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { Home } from "./pages/Home";
import {About}  from "./pages/About";
import { Service } from "./pages/Service";
import { Product } from "./pages/Product";
import { Contact } from "./pages/Contact";
import { NoMatch } from "./pages/NoMatch";
import { Layout } from "./components/Layout";
import NavigationBar  from "./components/NavigationBar";
import { Jumbotron } from "./components/Jumbotron";
import LoginPage from "./pages/LoginPage";
import CreatePost from "./pages/CreatePost";
import PostPage from "./pages/PostPage";
import ArticlePage from "./pages/ArticlePage";
import EditPost from "./pages/EditPost";
import { UserContextProvider } from "./components/UserContext";
import { UserContext } from "./components/UserContext";
import RegisterPage from "./pages/RegisterPage";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import ProductionForm from "./pages/ProductionForm";
import ProductsList from "./pages/ProductsList";
// import AllPostsPage from "./pages/AllPostsPage";
import SingleCategoryPage  from "./pages/SingleCategoryPage";
import CategoryPostsPage from "./pages/CategoryPostsPage";
import ProductionCard from "./components/ProductionCard";
import CreateArticle from "./pages/CreateArticle";
import Blog from "./pages/Blog";
import EditArticle from "./pages/EditArticle";

const ProtectedRoute = ({ children }) => {
  const { userInfo } = useContext(UserContext);
  const isUserLoggedIn = !!userInfo?.email;

  if (!isUserLoggedIn) {
    return <Navigate to="/login" replace />;
  }
  return children;
};

const theme = createTheme();


function App() {
  return (
    <ThemeProvider theme={theme}>
    <UserContextProvider>
      <Router>
        <NavigationBar />
        <Jumbotron />
        <Layout>
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/service" element={<Service />} />
            <Route path="/product" element={<Product />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/blog" element={<Blog />} />

            <Route path="/Saisonales/Fruehling" element={<SingleCategoryPage category={"frueling"} />} />
            <Route path="/Saisonales/Sommer" element={<SingleCategoryPage category={"sommer"} />} />
            <Route path="/Saisonales/Herbst" element={<SingleCategoryPage category={"herbst"} />} />
            <Route path="/Saisonales/Winter" element={<SingleCategoryPage category={"winter"} />} /> 

            <Route path="/kategorien/vorspeisen" element={<SingleCategoryPage category={"vorspeisen"} />} />
            <Route path="/kategorien/hauptgerichte" element={<SingleCategoryPage category={"hauptgerichte"} />} />
            <Route path="/kategorien/desserts" element={<SingleCategoryPage category={"desserts"} />} /> 
            {/* TODO: define lazy loading */}
            <Route path="/rezepte/alle" element={<CategoryPostsPage />} />
            {/* TODO: define  "/rezepte/beliebt" and "/rezepte/neu" */}
            <Route path="/rezepte/beliebt" element={<SingleCategoryPage category={"beliebt"} />} />
            <Route path="/rezepte/neu" element={<SingleCategoryPage category={"neu"} />} />
           
            
            <Route path="/associate/:id" element={<ProductionCard />} />

          <Route path="/associate" element={<ProductionCard />} />    {/* this is should be deleted */}


            <Route path="/login" element={<LoginPage />} />
            <Route path="/register" element={<RegisterPage />} /> 
            <Route path="/post/:id" element={<PostPage />} />
            <Route path="/article/:id" element={<ArticlePage />} />
            <Route path="/edit/:id" element={<EditPost />} />
            <Route path="/edit-article/:id" element={<EditArticle />} />
            <Route path="*" element={<NoMatch />} />
            <Route path="/create" element={
              <ProtectedRoute>
                <CreatePost />
              </ProtectedRoute>
              } />
               <Route path="/create-article" element={
              <ProtectedRoute>
                <CreateArticle />
              </ProtectedRoute>
              } />
              <Route path="/create-associate-product" element={
              <ProtectedRoute>
                <ProductionForm />
              </ProtectedRoute>
              } />ProductsList
                  <Route path="/associate-product-list" element={
              <ProtectedRoute>
                <ProductsList />
              </ProtectedRoute>
              } />
              
          </Routes>
        </Layout>
      </Router>
    </UserContextProvider>
    </ThemeProvider>
  );
}

export default App;
