import { useEffect, useState, useMemo } from "react";
import { Navigate, useParams } from "react-router-dom";
import Editor from "../components/Editor";
import styled from "@emotion/styled";
import { CircularProgress } from "@mui/material";
import Select from "react-select";
import "../components/blog.css";

const SelectContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const Spinner = styled.div`
  display: ${({ loading }) => (loading ? "flex" : "none")};
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  z-index: 999;
`;

export default function EditPost() {
  const { id } = useParams();
  const [title, setTitle] = useState("");
  const [summary, setSummary] = useState("");
  const [content, setContent] = useState("");
  const [redirect, setRedirect] = useState(false);
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [category, setCategory] = useState([]);
  const [id1, setId1] = useState("");
  const [id2, setId2] = useState("");
  const [id3, setId3] = useState("");

  const url = process.env.REACT_APP_URL;

  const categoryOptions = useMemo(
    () => [
      { value: "frueling", label: "Frühling" },
      { value: "sommer", label: "Sommer" },
      { value: "herbst", label: "Herbst" },
      { value: "winter", label: "Winter" },
      { value: "vorspeisen", label: "Vorspeisen" },
      { value: "hauptgerichte", label: "Hauptgerichte" },
      { value: "suppen", label: "Suppen" },
      { value: "salate", label: "Salate" },
      { value: "beilagen", label: "Beilagen" },
      { value: "desserts", label: "Desserts" },
      { value: "gebaeck", label: "Gebäck" },
      { value: "vegetarisch", label: "Vegetarisch" },
      { value: "vegan", label: "Vegan" },
      { value: "fisch", label: "Fisch" },
      { value: "fleisch", label: "Fleisch" },
      { value: "pasta", label: "Pasta" },
      { value: "auflaeufe", label: "Aufläufe" },
      { value: "eintopf", label: "Eintopf" },
      { value: "fruehstueck", label: "Frühstück" },
      { value: "getraenke", label: "Getränke" },
      { value: "saucen", label: "Saucen" },
      { value: "snacks", label: "Snacks" },
      { value: "regional", label: "Regionale Küche" },
      { value: "international", label: "Internationale Küche" },
    ],
    []
  );

  const handleFileChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      setFile(e.target.files[0]);
    }
  };

  useEffect(() => {
    fetch(`${url}/post/` + id).then((response) => {
      response.json().then((postInfo) => {

        const cats = postInfo.category;

        // Ensure postInfo.associatedIds is an array
        const associateIds = postInfo.associatedIds;
      
        setId1(associateIds[0] || "");
        setId2(associateIds[1] || "");
        setId3(associateIds[2] || "");

        setTitle(postInfo.title);
        setContent(postInfo.content);
        setSummary(postInfo.summary);
        setCategory(
          cats.map((value) => {
            const category = categoryOptions.find(
              (option) => option.value === value
            );
            return category ? category : { value, label: value };
          })
        );
      });
    });
  }, [url, id, categoryOptions]);

  async function updatePost(e) {
    e.preventDefault();
    const ImageUrl = await handleUpload();
    const data = new FormData();
    data.set("title", title);
    data.set("summary", summary);
    data.set("content", content);
    // Add associated IDs as an array
    const associatedIds = [id1, id2, id3].filter(Boolean);
    
    data.set("associatedIds", JSON.stringify(associatedIds));
    // Append each category value separately
    category.forEach((cat, index) => {
      data.append(`category[${index}]`, cat.value);
    });

    data.set("id", id);

    if (file) {
      data.set("file", ImageUrl);
    }

    const response = await fetch(`${url}/post/`, {
      method: "PUT",
      body: data,
      credentials: "include",
    });
    if (response.ok) {
      setRedirect(true);
    }
  }

  const handleUpload = async () => {
    if (file === null) return null;
    try {
      setLoading(true);
      const data = new FormData();
      data.append("postImage", file);
      const res = await fetch(`${url}/upload/`, {
        method: "POST",
        body: data,
        credentials: "include",
      });

      if (!res.ok) {
        throw new Error(`HTTP error! status: ${res.status}`);
      }

      const result = await res.json();
      return result.url;
    } catch (error) {
      alert(error.message);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const handleCategoryChange = (selectedOptions) => {
    
    const values = selectedOptions || [];
    setCategory(values);
  };

  if (redirect) {
    return <Navigate to={"/post/" + id} />;
  }

  return (
    <Container>
      <Spinner loading={loading}>
        <CircularProgress />
      </Spinner>
      <form onSubmit={updatePost}>
        <input
          type="title"
          placeholder={"Title"}
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
        <input
          type="summary"
          placeholder={"Summary"}
          value={summary}
          onChange={(e) => setSummary(e.target.value)}
        />
        <SelectContainer>
          <input type="file" onChange={handleFileChange} disabled={loading} />
          <Select
            value={category}
            isMulti
            name="categories"
            options={categoryOptions}
            className="basic-multi-select"
            classNamePrefix="select"
            onChange={handleCategoryChange}
          />
        </SelectContainer>
        <Editor onChange={setContent} value={content} />
        <input
          type="text"
          placeholder="Associated Id 1"
          value={id1}
          style={{ marginTop: "5px" }}
          onChange={(e) => setId1(e.target.value)}
        />
        <input
          type="text"
          placeholder="Associated Id 2"
          value={id2}
          style={{ marginTop: "5px" }}
          onChange={(e) => setId2(e.target.value)}
        />
        <input
          type="text"
          placeholder="Associated Id 3"
          value={id3}
          style={{ marginTop: "5px" }}
          onChange={(e) => setId3(e.target.value)}
        />
        <button
          style={{
            marginTop: "5px",
            opacity: loading ? 0.5 : 1,
            cursor: loading ? "not-allowed" : "pointer",
          }}
          disabled={loading}
        >
          Update post
        </button>
      </form>
    </Container>
  );
}
