import "react-quill/dist/quill.snow.css";
import { useState, useCallback } from "react";
import { Navigate } from "react-router-dom";
import Editor from "../components/Editor";
import { Alert, CircularProgress } from "@mui/material";
import styled from "styled-components";
import Select from "react-select";

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export default function CreateArticle() {
  const [title, setTitle] = useState("");
  const [summary, setSummary] = useState("");
  const [content, setContent] = useState("");
  const [redirect, setRedirect] = useState(false);
  const [errors, setErrors] = useState({});
  const [category, setCategory] = useState([]);
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [id1, setId1] = useState("");
  const [id2, setId2] = useState("");
  const [id3, setId3] = useState("");

  const url = process.env.REACT_APP_URL;
  const categoryOptions = [
    { value: "gesundheit_ernaehrung", label: "Gesundheit & Ernährung" },
    { value: "fitness_wellness", label: "Fitness & Wellness" },
    { value: "lebensstil_trends", label: "Lebensstil & Trends" }
  ];

  const handleFileChange = useCallback((e) => {
    if (e.target.files && e.target.files[0]) {
      setFile(e.target.files[0]);
    }
  }, []);

  const validator = useCallback(() => {
    const newErrors = {};

    if (!title || title.trim().length === 0) {
      newErrors.title = "The title is required";
    }
    if (!summary || summary.trim().length === 0) {
      newErrors.summary = "The summary is required";
    }
    if (!content || content.trim().length === 0 || content === "<p><br></p>") {
      newErrors.content = "The content is required";
    }

    return newErrors;
  }, [title, summary, content]);

  const handleUpload = useCallback(async () => {
    if (!file) return null;

    try {
      setLoading(true);
      const data = new FormData();
      data.append("postImage", file);

      const res = await fetch(`${url}/upload/`, {
        method: "POST",
        body: data,
        credentials: "include",
      });

      if (!res.ok) {
        throw new Error(
          `Failed to upload image: HTTP error! status: ${res.status}`
        );
      }

      const result = await res.json();
      return result.url;
    } catch (error) {
      console.error("Error uploading image:", error);
      throw error;
    } finally {
      setLoading(false);
    }
  }, [file, url]);

  const createNewPost = useCallback(
    async (e) => {
      e.preventDefault();

      const newErrors = validator();

      if (Object.keys(newErrors).length === 0) {
        try {
          const ImageUrl = await handleUpload();
          const data = new FormData();
          data.set("title", title);
          data.set("summary", summary);
          data.set("content", content);
          if (ImageUrl) data.set("file", ImageUrl);
          // Add associated IDs as an array
          const associatedIds = [id1, id2, id3].filter(Boolean);
          data.set("associatedIds",  JSON.stringify(associatedIds));
         
          category.forEach((cat, index) => {
            data.append(`category[${index}]`, cat);
          });
          
          const response = await fetch(`${url}/create-article/`, {
            method: "POST",
            body: data,
            credentials: "include",
          });

          if (response.ok) {
            setRedirect(true);
          } else {
            console.error("Failed to create post:", response.statusText);
          }
        } catch (error) {
          console.error("Error creating post:", error);
        }
      } else {
        setErrors(newErrors);
      }
    },
    [title, summary, content, category, handleUpload, url, validator, id1, id2, id3]
  );

  const handleCategoryChange = useCallback((selectedOptions) => {
    const values = selectedOptions
      ? selectedOptions.map((option) => option.value)
      : [];
    setCategory(values);
  }, []);

  if (redirect) {
    return <Navigate to={"/blog"} />;
  }

  return (
    <form onSubmit={createNewPost}>
      <h1>Create New Article</h1>
      {errors.title && <Alert severity="error">{errors.title}</Alert>}
      <input
        type="text"
        placeholder="Title"
        value={title}
        style={{ borderColor: errors.title ? "red" : "", marginTop: "5px" }}
        onChange={(e) => setTitle(e.target.value)}
      />
      {errors.summary && <Alert severity="error">{errors.summary}</Alert>}
      <input
        type="text"
        placeholder="Summary"
        value={summary}
        style={{ borderColor: errors.summary ? "red" : "", marginTop: "5px" }}
        onChange={(e) => setSummary(e.target.value)}
      />
      {errors.files && <Alert severity="error">{errors.files}</Alert>}
      <Container>
        <input
          type="file"
          onChange={handleFileChange}
          disabled={loading}
          style={{
            borderColor: errors.files ? "red" : "",
            marginTop: "5px",
            flex: 1,
            maxWidth: "50%",
          }}
        />
        <Select
          isMulti
          name="categories"
          options={categoryOptions}
          className="basic-multi-select"
          classNamePrefix="select"
          onChange={handleCategoryChange}
          value={categoryOptions.filter((option) =>
            category.includes(option.value)
          )}
        />
      </Container>
      {errors.content && <Alert severity="error">{errors.content}</Alert>}
      <Editor
        value={content}
        onChange={setContent}
        style={{ borderColor: errors.content ? "red" : "", marginTop: "5px" }}
      />

      <input
        type="text"
        placeholder="Associated Id 1"
        value={id1}
        style={{ marginTop: "5px" }}
        onChange={(e) => setId1(e.target.value)}
      />
      <input
        type="text"
        placeholder="Associated Id 2"
        value={id2}
        style={{ marginTop: "5px" }}
        onChange={(e) => setId2(e.target.value)}
      />
      <input
        type="text"
        placeholder="Associated Id 3"
        value={id3}
        style={{ marginTop: "5px" }}
        onChange={(e) => setId3(e.target.value)}
      />

      <button
        type="submit"
        style={{
          marginTop: "5px",
          opacity: loading ? 0.5 : 1,
          cursor: loading ? "not-allowed" : "pointer",
        }}
        disabled={loading}
      >
        {loading ? <CircularProgress size={24} /> : "Create post"}
      </button>
    </form>
  );
}