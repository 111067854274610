import React from 'react';
import { Container } from 'react-bootstrap'; // Ensure you're using Container from react-bootstrap correctly or replace it with MUI's Container if needed
import styled from 'styled-components';
import Footer from './Footer'; // Ensure the path to Footer is correct

const Styles = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 70vh; /* Ensures the layout takes at least the full viewport height */
`;

const MainContent = styled.main`
  flex: 1; /* This will make the main content area expand to push the footer down */
  display: flex;
  flex-direction: column;
`;

const FooterContainer = styled.div`
  width: 100%;
  color: #797979; /* Text color in footer */
  text-align: center;
  padding-top: 20px;
  z-index: 1000; /* Ensures footer is on top of other content */
`;

export const Layout = (props) => {
  return (
    <Styles>
      <MainContent>
        <Container>{props.children}</Container>
      </MainContent>
      <FooterContainer>
        <Footer />
      </FooterContainer>
    </Styles>
  );
};