import React from "react";
import { Jumbotron as Jumbo, Container } from "react-bootstrap";
import styled from "styled-components";
import cookingImage from "../assets/front-view-delicious-food-concept.jpeg"; // Replace with an appropriate cooking-related image

const Styles = styled.div`
  .jumbo {
    background: url(${cookingImage}) no-repeat center center;
    background-size: cover;
    color: #F1FAEE; // Soft, warm white for text
    height: 300px; // Increased height for better visual impact
    position: relative;
    z-index: 0;
    margin-top: 85px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .overlay {
    background-color: #1D3557; // Deep navy-black for overlay
    opacity: 0.7;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1;
  }

  .content {
    position: relative;
    z-index: 2;
  }

  h1 {
    font-size: 3rem;
    font-weight: bold;
    margin-bottom: 1rem;
  }

  p {
    font-size: 1.5rem;
    color: #A8DADC; // Mint green for subtitle
  }
`;

export const Jumbotron = () => (
  <Styles>
    <Jumbo fluid className="jumbo">
      <div className="overlay" />
      <Container className="content">
        <h1>Gesunde Rezepte für Sie</h1>
        <p>Schnell, einfach und lecker</p>
      </Container>
    </Jumbo>
  </Styles>
);