import React, { useState } from "react";
import {
  TextField,
  Button,
  Container,
  Typography,
  Box,
  Alert,
  Grid,
} from "@mui/material";
import { styled } from "@mui/system";

const StyledForm = styled("form")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(3),
  maxWidth: "800px",
  margin: "auto",
  padding: theme.spacing(4),
  backgroundColor: "#ffffff",
  borderRadius: theme.shape.borderRadius,
  boxShadow: "0 8px 16px rgba(0, 0, 0, 0.1)",
}));

const ImagePreview = styled("img")({
  width: "100%",
  height: "90px",
  objectFit: "cover",
  borderRadius: "4px",
  marginTop: "8px",
});

const ProductionForm = () => {
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [loading, setLoading] = useState(false);
  const [productName, setProductName] = useState("");
  const [associateUrl, setAssociateUrl] = useState("");
  const [description, setDescription] = useState("");
  const [images, setImages] = useState({});
  const [keyFeature1, setKeyFeature1] = useState("");
  const [keyFeature2, setKeyFeature2] = useState("");
  const [keyFeature3, setKeyFeature3] = useState("");
  const url = process.env.REACT_APP_URL;

  const handleImageChange = (event, num) => {
    const { value } = event.target;
    setImages((prev) => ({
      ...prev,
      [`imageUrl${num}`]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    try {
      const productData = {
        imageUrl1: images.imageUrl1,
        imageUrl2: images.imageUrl2,
        imageUrl3: images.imageUrl3,
        imageUrl4: images.imageUrl4,
        productName,
        associateUrl,
        description,
        keyFeatures: [keyFeature1, keyFeature2, keyFeature3],
      };

      const response = await fetch(`${url}/products`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(productData),
        credentials: "include",
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      setSuccess("Form submitted successfully!");
      setError("");

      // Clear the form
      setImages({
        imageUrl1: "",
        imageUrl2: "",
        imageUrl3: "",
        imageUrl4: "",
      });
      setKeyFeature1("");
      setKeyFeature2("");
      setKeyFeature3("");
      setProductName("");
      setAssociateUrl("");
      setDescription("");
    } catch (err) {
      setError("There was an error submitting the form.");
      setSuccess("");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container>
      <Box my={6}>
        <Typography
          variant="h4"
          component="h1"
          gutterBottom
          align="center"
          sx={{ mb: 4 }}
        >
          Add Associate Product to Database
        </Typography>

        <StyledForm onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            {[1, 2, 3, 4].map((num) => (
              <Grid item xs={12} sm={6} key={num}>
                <TextField
                  name={`imageUrl${num}`}
                  label={`Image URL ${num}`}
                  variant="outlined"
                  fullWidth
                  required
                  onChange={(e) => handleImageChange(e, num)}
                  value={images[`imageUrl${num}`] || ""}
                />
                {images[`imageUrl${num}`] && (
                  <ImagePreview
                    src={images[`imageUrl${num}`]}
                    alt={`Preview ${num}`}
                  />
                )}
              </Grid>
            ))}
          </Grid>
          <TextField
            name="productName"
            label="Product Name"
            variant="outlined"
            fullWidth
            required
            onChange={(e) => setProductName(e.target.value)}
            value={productName}
          />
          <TextField
            name="associateUrl"
            label="Associate URL"
            variant="outlined"
            fullWidth
            required
            onChange={(e) => setAssociateUrl(e.target.value)}
            value={associateUrl}
          />
          <TextField
            name="keyFeature1"
            label="Key Feature 1"
            variant="outlined"
            fullWidth
            onChange={(e) => setKeyFeature1(e.target.value)}
            value={keyFeature1}
          />
          <TextField
            name="keyFeature2"
            label="Key Feature 2"
            variant="outlined"
            fullWidth
            onChange={(e) => setKeyFeature2(e.target.value)}
            value={keyFeature2}
          />
          <TextField
            name="keyFeature3"
            label="Key Feature 3"
            variant="outlined"
            fullWidth
            onChange={(e) => setKeyFeature3(e.target.value)}
            value={keyFeature3}
          />
          <TextField
            name="description"
            label="Description"
            variant="outlined"
            fullWidth
            multiline
            rows={4}
            required
            onChange={(e) => setDescription(e.target.value)}
            value={description}
          />
          <Button
            type="submit"
            variant="contained"
            color="primary"
            size="large"
            sx={{ mt: 2 }}
            disabled={loading}
          >
            Submit
          </Button>
          {error && (
            <Alert severity="error" sx={{ mb: 2 }}>
              {error}
            </Alert>
          )}
          {success && (
            <Alert severity="success" sx={{ mb: 2 }}>
              {success}
            </Alert>
          )}
        </StyledForm>
      </Box>
    </Container>
  );
};

export default ProductionForm;
