import React from "react";
import { CircularProgress, Box, Grid, Typography, Container } from "@mui/material";
import styled from "styled-components";
import Category from "../components/Category";

const LoadingContainer = styled(Box)`
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
  background-color: red;
  border: 1px solid red;
`;

const SingleCategoryPage = ({ category }) => {
  if (category === null) {
    return (
      <LoadingContainer>
        <CircularProgress />
        <Typography variant="body1">Network is slow, please wait...</Typography>
      </LoadingContainer>
    );
  }

  return (
    <Container maxWidth="xl">
      <Grid
        container
        spacing={3}
        sx={{
          display: "flex",
          justifyContent: "center",
          padding: "16px",
        }}
      >
        <Grid item xs={12}>
          <Category postCategory={category} />
        </Grid>
      </Grid>
    </Container>
  );
};

export default SingleCategoryPage;