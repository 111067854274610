import React, { useRef, useState, useEffect, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Container, Nav, Navbar, NavDropdown } from "react-bootstrap";
import styled from "styled-components";
import logo from "../assets/logo.png";
import { UserContext } from "./UserContext";

const Styles = styled.div`
  .navbar {
    background-color: #1D3557 !important; /* Deep navy-black for a more distinct navbar */
    min-height: 85px;
  }

  a,
  .navbar-brand,
  .navbar-nav .nav-link {
    color: #F1FAEE; /* Soft, warm white for text */
    &:hover {
      color: #E63946; /* Vibrant red for hover effects */
    }
  }

  .navbar-logo {
    width: clamp(24px, calc(100vw - 40px), 160px);
    height: auto;
    aspect-ratio: 160 / 24;
    max-width: 100%;
    border-radius: 1px;
  }

  .dropdown-menu {
    background-color: #34598d; /* Deep navy-black for dropdown menus */
    border: none; /* Remove border for a cleaner look */
  }

  .dropdown-item {
    color: #F1FAEE !important; /* Soft, warm white for dropdown items */
    font-weight: 700;
    &:hover {
      font-weight: 400;
      color: #E63946 !important; /* Vibrant red for hover text */
      background-color: transparent; /* No background color change */
    }
  }

  .navbar-toggler {
    border-color: #fa1d1d; /* Honey yellow for toggler border */
  }

  .navbar-toggler-icon {
    background-color: #fa1d1d; /* Honey yellow for toggler icon */
  }
`;


const NavigationBar = () => {
  const url = process.env.REACT_APP_URL;

  const { setUserInfo, userInfo } = useContext(UserContext);
  const navigate = useNavigate();
  const [expanded, setExpanded] = useState(false);
  const navRef = useRef(null);

  const toggleMenu = () => setExpanded((prevExpanded) => !prevExpanded);
  const closeMenu = () => setExpanded(false);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (navRef.current && !navRef.current.contains(event.target)) {
        closeMenu();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const response = await fetch(`${url}/profile`, {
          credentials: "include",
        });
        const userInfo = await response.json();
        setUserInfo(userInfo);
      } catch (error) {
        console.error("Error fetching user info:", error);
      }
    };

    fetchUserInfo();
  }, [setUserInfo, url]);

  const logout = async () => {
    try {
      await fetch(`${url}/logout`, {
        credentials: "include",
        method: "POST",
      });
      localStorage.removeItem("userInfo");
      setUserInfo(null);
      navigate("/");
    } catch (error) {
      console.error("Error during logout:", error);
    }
  };

  const email = userInfo?.email;

  return (
    <Styles>
      <Navbar
        ref={navRef}
        expand="lg"
        expanded={expanded}
        className="fixed-top navbar-light"
      >
        <Container>
          <Navbar.Brand href="/">
            <img
              src={logo}
              alt="recipe for you"
              className="navbar-logo d-inline-block align-top"
            />
          </Navbar.Brand>
          <Navbar.Toggle
            aria-controls="basic-navbar-nav"
            onClick={toggleMenu}
            style={{ width: "70px" }}
          />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ml-auto">
              <Nav.Item>
                <Nav.Link onClick={closeMenu} as={Link} to="/">
                  Startseite
                </Nav.Link>
              </Nav.Item>
              <NavDropdown title="Rezepte" id="basic-nav-dropdown">
                <NavDropdown.Item
                  as={Link}
                  to="/rezepte/alle"
                  onClick={closeMenu}
                >
                  Alle Rezepte
                </NavDropdown.Item>
                <NavDropdown.Item
                  as={Link}
                  to="/rezepte/beliebt"
                  onClick={closeMenu}
                >
                  Beliebteste Rezepte
                </NavDropdown.Item>
                <NavDropdown.Item
                  as={Link}
                  to="/rezepte/neu"
                  onClick={closeMenu}
                >
                  Neueste Rezepte
                </NavDropdown.Item>
              </NavDropdown> 
               <NavDropdown title="Kategorien" id="basic-nav-dropdown">
                <NavDropdown.Item
                  as={Link}
                  to="/kategorien/vorspeisen"
                  onClick={closeMenu}
                >
                  Vorspeisen
                </NavDropdown.Item>
                <NavDropdown.Item
                  as={Link}
                  to="/kategorien/hauptgerichte"
                  onClick={closeMenu}
                >
                  Hauptgerichte
                </NavDropdown.Item>
                <NavDropdown.Item
                  as={Link}
                  to="/kategorien/desserts"
                  onClick={closeMenu}
                >
                  Desserts
                </NavDropdown.Item>
              </NavDropdown> 

               <NavDropdown title="Saisonales" id="basic-nav-dropdown">
                <NavDropdown.Item
                  as={Link}
                  to="/Saisonales/Fruehling"
                  onClick={closeMenu}
                >
                  Frühling
                </NavDropdown.Item>
                <NavDropdown.Item
                  as={Link}
                  to="/Saisonales/Sommer"
                  onClick={closeMenu}
                >
                  Sommer
                </NavDropdown.Item>
                <NavDropdown.Item
                  as={Link}
                  to="/Saisonales/Herbst"
                  onClick={closeMenu}
                >
                  Herbst
                </NavDropdown.Item>
                <NavDropdown.Item
                  as={Link}
                  to="/Saisonales/Winter"
                  onClick={closeMenu}
                >
                  Winter
                </NavDropdown.Item>
              </NavDropdown> 
              <Nav.Item>
                <Nav.Link onClick={closeMenu} as={Link} to="/blog">
                  Blog
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link onClick={closeMenu} as={Link} to="/about">
                  Über uns
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link onClick={closeMenu} as={Link} to="/contact">
                  Kontakt
                </Nav.Link>
              </Nav.Item>
              {email ? (
                <NavDropdown title="Account" id="account-nav-dropdown">
                  <NavDropdown.Item as={Link} to="/create" onClick={closeMenu}>
                    Create new post
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/create-article" onClick={closeMenu}>
                    Create new article
                  </NavDropdown.Item>
                 

                  <NavDropdown.Item
                    as={Link}
                    to="/create-associate-product"
                    onClick={closeMenu}
                  >
                    Create new Associate Product
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    as={Link}
                    to="/associate-product-list"
                    onClick={closeMenu}
                  >
                    Associate Product List
                  </NavDropdown.Item>
                  <NavDropdown.Item onClick={logout}>
                    Logout ({email})
                  </NavDropdown.Item>
                </NavDropdown>
              ) : (
                <>
                  <Nav.Item>
                    <Nav.Link onClick={closeMenu} as={Link} to="/login">
                      Anmelden
                    </Nav.Link>
                  </Nav.Item>
                </>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </Styles>
  );
};

export default NavigationBar;