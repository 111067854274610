import React, { useEffect, useState } from "react";
import { CircularProgress, Grid, Typography, Card, CardContent, CardActionArea, Container, CardMedia } from "@mui/material";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

const ProductName = styled(Typography)(({ theme }) => ({
  fontWeight: "bold",
  marginBottom: "0.5rem",
  fontSize: "1rem",
  '@media (min-width:600px)': {
    fontSize: "1.2rem",
  },
}));



const Category = ({ postCategory }) => {
  const [posts, setPosts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        console.log('post categories', postCategory);
        
        const url = process.env.REACT_APP_URL;
        const response = await fetch(`${url}/post-category`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ category: postCategory }),
        });

        if (!response.ok) {
          const err = await response.json();
          throw new Error(`Network response was not ok, ${err.message}`);
        }

        const data = await response.json();
        setPosts(data);
      } catch (error) {
        setError(error.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchPosts();
  }, [postCategory]);

  if (isLoading) {
    return (
      <div style={{ textAlign: "center", marginTop: "50px" }}>
        <CircularProgress />
        <div>Loading posts, please wait...</div>
      </div>
    );
  }

  if (error) {
    return (
      <div style={{ textAlign: "center", marginTop: "50px" }}>
        <Typography color="error">Error: {error}</Typography>
      </div>
    );
  }

  const handleClick = (id) => {
    navigate(`/post/${id}`);
  };

  return (
    <Container maxWidth="xl">
      <Grid container spacing={3}>
        {/* {posts.map((post, index) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={post._id}>
            <Card
              sx={{
                height: "100%",
                display: 'flex',
                flexDirection: 'column',
                backgroundColor: index % 2 === 0 ? "#f0f0f0" : "#ffffff",
              }}
              onClick={() => handleClick(post._id)}
            >
              <CardActionArea sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
                {post.cover ? (
                  <Box
                    component="img"
                    src={post.cover}
                    alt={post.title}
                    sx={{
                      height: 0,
                      paddingTop: '56.25%', // 16:9 aspect ratio
                      objectFit: 'cover',
                    }}
                  />
                ) : (
                  <PlaceholderBox>
                    <ImageIcon 
                      sx={{ 
                        fontSize: 60, 
                        color: '#9e9e9e',
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)'
                      }} 
                    />
                  </PlaceholderBox>
                )}
                <CardContent sx={{ flexGrow: 1 }}>
                  <ProductName>{post.title}</ProductName>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        ))} */}

{posts.map((post, index) => (
          <Card
            sx={{
              maxWidth: 350,
              width: '100%',
              height: "100%",
              margin:'10px',
              backgroundColor: index % 2 === 0 ? "#f0f0f0" : "#ffffff",
            }}
            onClick={() => handleClick(post._id)}
          >
            <CardActionArea sx={{ height: "100%" }}>
              <CardMedia
                component="img"
                image={post.cover}
                alt={post.title}
                sx={{
                  height: 140,
                  width: '100%',
                  position: 'absolute',
                  top: 0,
                  left: 0,
                }}
              />
              <CardContent sx={{ paddingTop: '140px' }}>
                <ProductName>{post.title}</ProductName>
              </CardContent>
            </CardActionArea>
          </Card>
      ))}
      </Grid>
    </Container>
  );
};

export default Category;