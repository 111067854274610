import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  Box,
  Grid,
  Typography,
  Button,
  Rating,
  IconButton,
} from "@mui/material";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";
import { useParams } from "react-router-dom";

const MainContainer = styled(Box)`
  padding: 20px;
  display: flex;
  flex-direction: column;
`;

const ImageCarousel = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const MainImage = styled.img`
  width: auto;
  height: 500px;
  width: 100%;
  object-fit: cover;
  margin-bottom: 10px;
  min-height: 200px;
  background-size: container;
`;

const Thumbnails = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ThumbnailImage = styled.img`
  width: 60px;
  height: 60px;
  object-fit: cover;
  margin: 0 5px;
  cursor: pointer;
`;

const NavigationButton = styled(IconButton)`
  margin: 0 10px;
  width: 50px;
  height: 50px;
`;

const ProductDetails = styled(Box)`
  margin-left: 20px;
`;

// const Price = styled(Typography)`
//   font-size: 24px;
//   font-weight: bold;
//   color: #b12704;
// `;

// const DiscountedPrice = styled(Typography)`
//   text-decoration: line-through;
//   margin-right: 10px;
//   color: grey;
// `;

// const AdditionalSavings = styled(Typography)`
//   color: green;
// `;

const ProductionCard = () => {
  const { id } = useParams();
  const [productData, setProductData] = useState({});
  const [images, setImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);
  const url = process.env.REACT_APP_URL;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${url}/products/${id}`);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json(); // Assuming the response is JSON
        setProductData(data);
        const images = [
          data.imageUrl1,
          data.imageUrl2,
          data.imageUrl3,
          data.imageUrl4,
        ];
        setImages(images);
        if (images.length > 0) {
          setSelectedImage(images[0]); // Set the first image as the selected image
        }
      } catch (error) {
        console.error("Fetch error:", error);
      }
    };

    fetchData();
  }, [url, id]);

  const handleThumbnailClick = (imageUrl) => {
    setSelectedImage(imageUrl);
    setCurrentIndex(images.indexOf(imageUrl));
  };

  const handlePrevClick = () => {
    const newIndex = (currentIndex - 1 + images.length) % images.length;
    setSelectedImage(images[newIndex]);
    setCurrentIndex(newIndex);
  };

  const handleNextClick = () => {
    const newIndex = (currentIndex + 1) % images.length;
    setSelectedImage(images[newIndex]);
    setCurrentIndex(newIndex);
  };

  return (
    <MainContainer>
      <Grid container>
        <Grid item xs={12} md={6}>
          <ImageCarousel>
            <MainImage src={selectedImage} alt="Main Product" />
            <Thumbnails>
              <NavigationButton onClick={handlePrevClick}>
                <ArrowBackIos />
              </NavigationButton>
              {images.map((image, index) => (
                <ThumbnailImage
                  key={index}
                  src={image}
                  alt={`Thumbnail ${index + 1}`}
                  onClick={() => handleThumbnailClick(image)}
                />
              ))}
              <NavigationButton onClick={handleNextClick}>
                <ArrowForwardIos />
              </NavigationButton>
            </Thumbnails>
          </ImageCarousel>
        </Grid>
        <Grid item xs={12} md={6}>
          <ProductDetails>
            <Typography
              variant="h4"
              component="h2"
              gutterBottom
              sx={{
                fontSize: {
                  xs: "1rem", // mobile screens
                  sm: "1.5rem", // small screens
                  md: "1.75rem", // medium screens
                  lg: "2rem", // large screens
                  xl: "2.5rem", // extra large screens
                },
              }}
            >
              {productData.productName}
            </Typography>
            <Box display="flex" alignItems="center" mb={2}>
              <Rating value={4} readOnly />
              <Typography variant="body2" ml={1}>
                (1234 reviews)
              </Typography>
            </Box>
            {/* <Box display="flex" alignItems="center" mb={2}>
              <Price>$99.99</Price>
              <DiscountedPrice>$149.99</DiscountedPrice>
              <AdditionalSavings>Save $50.00</AdditionalSavings>
            </Box> */}
            <Box display="flex" mb={2}>
              <Button
                variant="contained"
                color="primary"
                size="medium"
                sx={{ mr: 5, p: 3, width: "70%" }}
                onClick={() =>
                  window.open(
                    productData.associateUrl,
                    "_blank",
                    "noopener,noreferrer"
                  )
                }
              >
                Jetzt bei Amazon kaufen
              </Button>
              {/* <Button variant="outlined" color="primary" size="large">
                Add to Cart
              </Button> */}
            </Box>
            <Box mb={2}>
              <Typography variant="body1">
                <strong>Key Features:</strong>
              </Typography>
              <ul>
                {/* <li>{productData?.keyFeatures[0]}</li>
                <li>{productData?.keyFeatures[1]}</li>
                <li>{productData?.keyFeatures[2]}</li> */}
                {productData.keyFeatures ? (
                  <>
                    {productData?.keyFeatures.map((feature) => (
                      <li>{feature}</li>
                    ))}
                  </>
                ) : (
                  <li>No key features available</li>
                )}
              </ul>
            </Box>
            <Box>
              <Typography variant="body1">
                <strong>Detaillierte Beschreibung:</strong>
              </Typography>
              <Typography variant="body2">
                {productData?.description}
              </Typography>
            </Box>
          </ProductDetails>
        </Grid>
      </Grid>
    </MainContainer>
  );
};

export default ProductionCard;
