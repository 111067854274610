import { useState } from "react";
import {
  Container,
  Typography,
  TextField,
  Link,
  Grid,
  Box,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { Facebook, Instagram, Email } from "@mui/icons-material";
import XIcon from '@mui/icons-material/X';
import styled from "styled-components";

// Styled Components
const FooterWrapper = styled.footer`
  background-color: #F1FAEE; /* White */
  color: #1D3557; /* Black */
  padding: 2rem 0;
  border-top: 1px solid #E5E5E5; /* Light Gray */
`;

const FooterSection = styled(Box)`
  margin-bottom: 1.5rem;
  text-align: left;
`;

const FooterLink = styled(Link)`
  display: flex;
  align-items: center;
  color: #1D3557; /* Black */
  text-decoration: none;
  margin: 0.5rem 0;
  font-size: 0.875rem;
  &:hover {
    text-decoration: underline;
    color: #E63946; /* Red */
  }
`;

const FooterBottom = styled.div`
  background: #1D3557; /* Black */
  color: #F1FAEE; /* White */
  text-align: center;
  padding: 1rem;
  border-top: 1px solid #E5E5E5; /* Light Gray */
`;

const SocialLink = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none;
  margin: 0.5rem 0;
  font-size: 1rem;
  color: #1D3557; /* Black */
  &:hover {
    text-decoration: underline;
  }
`;

const Footer = () => {
  const [email, setEmail] = useState("");

  const handleNewsletterSubmit = (e) => {
    e.preventDefault();
    console.log("Newsletter Subscription Email:", email);
    setEmail("");
  };

  return (
    <>
      <FooterWrapper>
        <Container maxWidth="lg">
          <Grid container spacing={6}>
            <Grid item xs={12} md={4}>
              <FooterSection>
                <Typography variant="h5" gutterBottom>
                  Subscribe
                </Typography>
                <form onSubmit={handleNewsletterSubmit}>
                  <TextField
                    label="Email"
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    variant="outlined"
                    size="small"
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton type="submit" edge="end">
                            <Email style={{ color: "#A8DADC" }} /> {/* Mint Green */}
                          </IconButton>
                        </InputAdornment>
                      ),
                      style: {
                        borderColor: "#E5E5E5", // Light Gray
                      },
                    }}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "#E5E5E5", // Light Gray
                        },
                        "&:hover fieldset": {
                          borderColor: "#A8DADC", // Mint Green
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "#A8DADC", // Mint Green
                        },
                        "& input": {
                          color: "#1D3557", // Black
                        },
                        "& input::placeholder": {
                          color: "#1D3557", // Black
                          opacity: 0.7,
                        },
                      },
                      "& .MuiInputLabel-root": {
                        color: "#1D3557", // Black
                      },
                    }}
                  />
                </form>
              </FooterSection>
            </Grid>

            <Grid item xs={12} md={4}>
              <FooterSection>
                <Typography variant="h5" gutterBottom>
                  Quicklinks
                </Typography>
                <FooterLink href="/about">
                  <Typography variant="body2">Über Uns</Typography>
                </FooterLink>
                <FooterLink href="/privacy">
                  <Typography variant="body2">Datenschutzerklärung</Typography>
                </FooterLink>
                <FooterLink href="/terms">
                  <Typography variant="body2">AGB</Typography>
                </FooterLink>
              </FooterSection>
            </Grid>

            <Grid item xs={12} md={4}>
              <FooterSection>
                <Typography variant="h5" gutterBottom>
                  Folge Uns
                </Typography>
                <SocialLink
                  href="https://www.facebook.com/recipeforyoudotde"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Facebook style={{ marginRight: "0.5rem", color: "#3b5998" }} /> {/* Facebook Blue */}
                  Facebook
                </SocialLink>
                <SocialLink
                  href="https://www.x.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <XIcon style={{ marginRight: "0.5rem", color: "#1da1f2" }} /> {/* X Blue */}
                  X-Platform
                </SocialLink>
                <SocialLink
                  href="https://www.instagram.com/recipe_for_you_de/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Instagram style={{ marginRight: "0.5rem", color: "#e1306c" }} /> {/* Instagram Pink */}
                  Instagram
                </SocialLink>
              </FooterSection>
            </Grid>
          </Grid>
        </Container>
      </FooterWrapper>

      <FooterBottom>
        <Container>
          <Typography variant="body2" align="center" sx={{ color: "#F1FAEE" }}>
            © 2024 Alle Rechte sind vorbehalten{" "}
            <Link
              href="https://ahmadalinaghian.info"
              color="inherit"
              underline="hover"
              target="_blank"
            >
              Ahmad Alinaghian
            </Link>
          </Typography>
        </Container>
      </FooterBottom>
    </>
  );
};

export default Footer;
