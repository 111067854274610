// import React, { useEffect, useState } from "react";
// import { CircularProgress, Grid, styled, Container, Button, Typography, IconButton } from "@mui/material";
// import Card from "@mui/material/Card";
// import CardContent from "@mui/material/CardContent";
// import CardMedia from "@mui/material/CardMedia";
// import { CardActionArea } from "@mui/material";
// import { useNavigate } from "react-router-dom";
// import { Carousel } from 'react-responsive-carousel';
// import 'react-responsive-carousel/lib/styles/carousel.min.css';
// import ArrowBackIos from '@mui/icons-material/ArrowBackIos';
// import ArrowForwardIos from '@mui/icons-material/ArrowForwardIos';

// const ProductName = styled((props) => (
//   <Typography variant="body1" component="p" {...props} />
// ))(({ theme }) => ({
//   fontWeight: "bold",
//   marginBottom: "0.5rem",
//   fontSize: "16px",
// }));

// const CarouselImageContainer = styled('div')(({ theme }) => ({
//   position: 'relative',
//   textAlign: 'center',
//   color: 'white',
//   height: '45vh',
//   [theme.breakpoints.down('sm')]: {
//     height: '30vh',
//   }
// }));

// const CarouselImage = styled('img')({
//   width: '100%',
//   height: 'auto',
//   objectFit: 'cover',
// });

// const TextBox = styled('div')(({ theme }) => ({
//   position: 'absolute',
//   top: '50%',
//   left: '50%',
//   transform: 'translate(-50%, -50%)',
//   backgroundColor: 'rgba(0, 0, 0, 0.5)',
//   padding: '20px',
//   borderRadius: '10px',
//   textAlign: 'center',
//   color: 'white',
//   width: '80%',
//   maxWidth: '90%',
//   [theme.breakpoints.down('sm')]: {
//     padding: '15px',
//     width: '70%',
//     maxWidth: '100%',
//   }
// }));

// const OverlayButton = styled(Button)({
//   marginTop: '20px',
//   width: '45%',
//   backgroundColor: 'rgba(0, 0, 0, 0.7)',
//   color: 'white',
//   '&:hover': {
//     backgroundColor: 'rgba(0, 0, 0, 0.9)',
//   }
// });

// const CustomArrow = styled(IconButton)(({ theme }) => ({
//   position: 'absolute',
//   top: '50%',
//   transform: 'translateY(-50%)',
//   zIndex: 1,
//   color: 'white',
//   backgroundColor: 'rgba(0, 0, 0, 0.5)',
//   borderRadius: '50%',
//   padding: '10px',
//   display: 'flex',
//   alignItems: 'center',
//   justifyContent: 'center',
//   width: '40px',
//   height: '40px',
//   [theme.breakpoints.down('sm')]: {
//     width: '40px',
//     height: '40px',
//     padding: '8px',
//   }
// }));

// const LeftArrow = styled(CustomArrow)({
//   left: '10px',
// });

// const RightArrow = styled(CustomArrow)({
//   right: '10px',
// });

// const LandingPage = () => {
//   const [posts, setPosts] = useState([]);
//   const [isLoading, setIsLoading] = useState(true);
//   const [error, setError] = useState(null);
//   const navigate = useNavigate();

//   useEffect(() => {
//     fetchPosts();
//   }, []);

//   const fetchPosts = async () => {
//     try {
//       const url = process.env.REACT_APP_URL || 'http://localhost:5000';
//       const response = await fetch(`${url}/post`);
//       if (!response.ok) {
//         throw new Error("Failed to fetch posts.");
//       }
//       const data = await response.json();
//       setPosts(data);
//     } catch (error) {
//       setError(error.message);
//     } finally {
//       setIsLoading(false);
//     }
//   };

//   const handleClick = (id) => {
//     navigate(`/post/${id}`);
//   };

//   if (isLoading) {
//     return (
//       <div style={{ textAlign: "center", marginTop: "50px" }}>
//         <CircularProgress />
//         <div>Loading posts, please wait...</div>
//       </div>
//     );
//   }

//   if (error) {
//     return (
//       <div style={{ textAlign: "center", marginTop: "50px" }}>
//         <Typography color="error">Error: {error}</Typography>
//       </div>
//     );
//   }

//   return (
//     <Container maxWidth="lg">
//       <Carousel
//         showThumbs={false}
//         autoPlay
//         infiniteLoop
//         interval={5000}
//         transitionTime={600}
//         showArrows={false} // We will provide custom arrows
//         showStatus={false}
//         showIndicators={true}
//         stopOnHover={true}
//         useKeyboardArrows={true}
//         renderArrowPrev={(clickHandler) => (
//           <LeftArrow onClick={clickHandler}>
//             <ArrowBackIos />
//           </LeftArrow>
//         )}
//         renderArrowNext={(clickHandler) => (
//           <RightArrow onClick={clickHandler}>
//             <ArrowForwardIos />
//           </RightArrow>
//         )}
//       >
//         <div>
//           <CarouselImageContainer>
//             <CarouselImage src="https://images.unsplash.com/photo-1498837167922-ddd27525d352?q=80&w=870&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="Slide 1" />
//             <TextBox>
//               <Typography variant="h6">Slide 1 Text</Typography>
//               <OverlayButton variant="contained" onClick={() => alert('Action 1')}>Action 1</OverlayButton>
//             </TextBox>
//           </CarouselImageContainer>
//         </div>
//         <div>
//           <CarouselImageContainer>
//             <CarouselImage src="https://images.unsplash.com/photo-1494390248081-4e521a5940db?q=80&w=1106&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="Slide 2" />
//             <TextBox>
//               <Typography variant="h6">Slide 2 Text</Typography>
//               <OverlayButton variant="contained" onClick={() => alert('Action 2')}>Action 2</OverlayButton>
//             </TextBox>
//           </CarouselImageContainer>
//         </div>
//         <div>
//           <CarouselImageContainer>
//             <CarouselImage src="https://images.unsplash.com/photo-1497888329096-51c27beff665?q=80&w=1171&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="Slide 3" />
//             <TextBox>
//               <Typography variant="h6">Slide 3 Text</Typography>
//               <OverlayButton variant="contained" onClick={() => alert('Action 3')}>Action 3</OverlayButton>
//             </TextBox>
//           </CarouselImageContainer>
//         </div>
//       </Carousel>

//       <Typography variant="h4" component="h2" sx={{ marginTop: 4, marginBottom: 2 }}>
//         New Posts
//       </Typography>

//       <Grid container spacing={2} sx={{ mb: 4 }}>
//         {posts.map((post, index) => (
//           <Grid item xs={12} sm={6} md={4} lg={3} key={post._id}>
//             <Card
//               sx={{
//                 maxWidth: 350,
//                 width: '100%',
//                 height: "100%",
//                 backgroundColor: index % 2 === 0 ? "#f0f0f0" : "#ffffff",
//                 display: 'flex',
//                 flexDirection: 'column',
//               }}
//               onClick={() => handleClick(post._id)}
//             >
//               <CardActionArea sx={{ height: "100%", display: 'flex', flexDirection: 'column' }}>
//                 <CardMedia
//                   component="img"
//                   image={post.cover}
//                   alt={post.title || "Post cover"}
//                   sx={{
//                     height: 140,
//                     width: '100%',
//                     objectFit: 'cover',
//                   }}
//                 />
//                 <CardContent sx={{ flexGrow: 1 }}>
//                   <ProductName>{post.title}</ProductName>
//                 </CardContent>
//               </CardActionArea>
//             </Card>
//           </Grid>
//         ))}
//       </Grid>
//     </Container>
//   );
// };

// export default LandingPage;

import React, { useEffect, useState } from "react";
import {
  CircularProgress,
  Grid,
  styled,
  Container,
  Button,
  Typography,
  IconButton,
} from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { CardActionArea } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import ArrowBackIos from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIos from "@mui/icons-material/ArrowForwardIos";
import obst_gemüse from "../assets/obst-gemüse.webp";
import Obst_Joghurt_Müsli from "../assets/Obst-Joghurt-Müsli.webp";
import obstsalat_schüssel from "../assets/obstsalat-schüssel.webp";

const ProductName = styled((props) => (
  <Typography variant="body1" component="p" {...props} />
))(({ theme }) => ({
  fontWeight: "bold",
  marginBottom: "0.5rem",
  fontSize: "16px",
}));

const CarouselImageContainer = styled("div")(({ theme }) => ({
  position: "relative",
  textAlign: "center",
  color: "white",
  height: "45vh",
  minHeight: "200px",
  [theme.breakpoints.down("sm")]: {
    height: "30vh",
  },
}));

const CarouselImage = styled("img")({
  width: "100%",
  height: "100%",
  objectFit: "cover",
});

const TextBox = styled("div")(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: "rgba(0, 0, 0, 0.5)",
  padding: "20px",
  borderRadius: "10px",
  textAlign: "center",
  color: "white",
  width: "80%",
  maxWidth: "90%",
  [theme.breakpoints.down("sm")]: {
    padding: "15px",
    width: "70%",
    maxWidth: "90%",
  },
}));

const OverlayButton = styled(Button)({
  marginTop: "20px",
  width: "45%",
  backgroundColor: "rgba(0, 0, 0, 0.7)",
  color: "white",
  "&:hover": {
    backgroundColor: "rgba(0, 0, 0, 0.9)",
  },
});

const CustomArrow = styled(IconButton)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  transform: "translateY(-50%)",
  zIndex: 1,
  color: "white",
  backgroundColor: "rgba(0, 0, 0, 0.5)",
  borderRadius: "50%",
  padding: "10px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "40px",
  height: "40px",
  [theme.breakpoints.down("sm")]: {
    width: "30px",
    height: "30px",
    padding: "6px",
  },
}));

const LeftArrow = styled(CustomArrow)({
  left: "10px",
});

const RightArrow = styled(CustomArrow)({
  right: "10px",
});

const LandingPage = () => {
  const [posts, setPosts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    fetchPosts();
  }, []);

  const fetchPosts = async () => {
    try {
      const url = process.env.REACT_APP_URL || "http://localhost:5000";
      const response = await fetch(`${url}/post`);
      if (!response.ok) {
        throw new Error("Failed to fetch posts.");
      }
      const data = await response.json();
      setPosts(data);
    } catch (error) {
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleClick = (id) => {
    navigate(`/post/${id}`);
  };


  return (
    <Container maxWidth="lg">
      <Carousel
        showThumbs={false}
        autoPlay
        infiniteLoop
        interval={5000}
        transitionTime={600}
        showArrows={false} // We will provide custom arrows
        showStatus={false}
        showIndicators={true}
        stopOnHover={true}
        useKeyboardArrows={true}
        renderArrowPrev={(clickHandler) => (
          <LeftArrow onClick={clickHandler}>
            <ArrowBackIos />
          </LeftArrow>
        )}
        renderArrowNext={(clickHandler) => (
          <RightArrow onClick={clickHandler}>
            <ArrowForwardIos />
          </RightArrow>
        )}
      >
        <div>
          <CarouselImageContainer>
            <CarouselImage src={obst_gemüse} alt="Slide 1" />
            <TextBox>
              <Typography variant="h6"> "Entdecke unser neuestes Rezept für gesunde und köstliche Gerichte! 🍽️ </Typography>
              <OverlayButton
                variant="contained"
                onClick={() => alert("Action 1")}
              >
                Jetzt Rezept ausprobieren
              </OverlayButton>
            </TextBox>
          </CarouselImageContainer>
        </div>
        <div>
          <CarouselImageContainer>
            <CarouselImage src={Obst_Joghurt_Müsli} alt="Slide 2" />
            <TextBox>
              <Typography variant="h6">Bleib immer auf dem Laufenden! Erhalte die besten Rezepte direkt in dein Postfach. 📬</Typography>
              <OverlayButton
                variant="contained"
                onClick={() => alert("Action 2")}
              >
                Newsletter abonnieren
              </OverlayButton>
            </TextBox>
          </CarouselImageContainer>
        </div>
        <div>
          <CarouselImageContainer>
            <CarouselImage src={obstsalat_schüssel} alt="Slide 3" />
            <TextBox>
              <Typography variant="h6">Werde Teil unserer Community! Teile deine Kreationen mit uns und inspiriere andere. 🌟</Typography>
              <OverlayButton
                variant="contained"
                onClick={() => alert("Action 3")}
              >
                Jetzt beitreten
              </OverlayButton>
            </TextBox>
          </CarouselImageContainer>
        </div>
      </Carousel>

      <Typography
        variant="h4"
        component="h2"
        sx={{ marginTop: 4, marginBottom: 2 }}
      >
        Neue Beiträge
      </Typography>

      <Grid container spacing={2} sx={{ mb: 4 }}>
        {(isLoading ||error) && (
          <div style={{ textAlign: "center", marginTop: "50px" }}>
            <CircularProgress />
            <div>Loading posts, please wait...</div>
          </div>
        )}
        {posts?.map((post, index) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={post._id}>
            <Card
              sx={{
                maxWidth: 350,
                width: "100%",
                height: "100%",
                backgroundColor: index % 2 === 0 ? "#f0f0f0" : "#ffffff",
                display: "flex",
                flexDirection: "column",
              }}
              onClick={() => handleClick(post._id)}
            >
              <CardActionArea
                sx={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <CardMedia
                  component="img"
                  image={post.cover}
                  alt={post.title || "Post cover"}
                  sx={{
                    height: 140,
                    width: "100%",
                    objectFit: "cover",
                  }}
                />
                <CardContent sx={{ flexGrow: 1 }}>
                  <ProductName>{post.title}</ProductName>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default LandingPage;
