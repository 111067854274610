import React, { useContext, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
// import { formatISO9075 } from "date-fns";
import { UserContext } from "../components/UserContext";
import { Link } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import styled from "styled-components";
import { 
  Button, 
  CircularProgress, 
  Dialog, 
  DialogActions, 
  DialogContent, 
  DialogContentText, 
  DialogTitle,
  Typography,
  Box,
  Container
} from "@mui/material";
import ProductsBar from "../components/ProductBar";

const StyledContainer = styled(Container)`
  background: linear-gradient(
    90deg,
    rgba(217, 216, 230, 1) 0%,
    rgba(240, 240, 244, 1) 36%,
    rgba(181, 240, 252, 1) 100%
  );
  color: #222;
  padding: 20px;
`;

const StyledImage = styled.img`
  max-width: 100%;
  border-radius: 15px;
  object-fit: cover;
  object-position: center center;
  height: 100%;
  width: 100%;
`;

const ActionContainer = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  margin: 24px 0;
`;

const StyledButton = styled(Button)`
  && {
    text-transform: none;
    font-weight: 500;
    padding: 8px 16px;
    border-radius: 8px;
    transition: all 0.3s ease;
    width: 150px;
  }
`;

const EditButton = styled(StyledButton)`
  && {
    background-color: #4caf50;
    color: white;
    &:hover {
      background-color: #45a049;
    }
  }
`;

const DeleteButton = styled(StyledButton)`
  && {
    background-color: #f44336;
    color: white;
    &:hover {
      background-color: #d32f2f;
    }
  }
`;

const Content = styled(Box)`
  p {
    line-height: 1.7rem;
    margin: 30px 50px;
  }
  ul,ol{
    margin-left:30px; 
  }
  li {
    margin: 10px;
  }
  h2{
    font-size:calc(12px + 1vw)
  }
`;

export default function PostPage() {
  const { id } = useParams();
  const [postInfo, setPostInfo] = useState(null);
  const { userInfo } = useContext(UserContext);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const url = process.env.REACT_APP_URL;

  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    const fetchPostInfo = async () => {
      setLoading(true);
      try {
        const res = await fetch(`${url}/post/${id}`, { signal });
        if (!res.ok) throw new Error("Network response was not ok!");
        const data = await res.json();
        setPostInfo(data);
      } catch (err) {
        if (err.name !== "AbortError") setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchPostInfo();
    return () => abortController.abort();
  }, [url, id]);

  const deletePost = () => {
    fetch(`${url}/post/${id}`, { method: "DELETE" })
      .then((response) => response.json())
      .then((res) => {
        if (res.ok) navigate("/rezepte/alle");
        else console.log("Error deleting post: " + res.message);
      });
  };

  if (loading || postInfo === null) {
    return (
      <Box textAlign="center" mt={5}>
        <CircularProgress />
        <Typography>Network is slow, please wait...</Typography>
      </Box>
    );
  }

  if (error) return <Typography color="error">Error: {error}</Typography>;

  return (
    <StyledContainer maxWidth="md">

      {/* <Typography variant="body2" align="center" color="textSecondary">
        {formatISO9075(new Date(postInfo?.createdAt))}
      </Typography> */}
      {!userInfo.author && (
        <Box>
          {/* <Typography variant="subtitle2" align="center">
            by @{postInfo?.author.firstName + " " + postInfo?.author.lastName}
          </Typography> */}
          {userInfo.id === postInfo?.author.id && (
            <ActionContainer>
              <EditButton
                component={Link}
                to={`/edit/${postInfo?._id}`}
                startIcon={<EditIcon />}
              >
                Edit this post
              </EditButton>
              <DeleteButton
                onClick={handleClickOpen}
                startIcon={<DeleteForeverIcon />}
              >
                Delete Post
              </DeleteButton>
            </ActionContainer>
          )}
        </Box>
      )}
      <Box my={2}>
        <StyledImage src={postInfo?.cover} alt="" />
      </Box>
      <Typography variant="h1" align="center" fontSize="calc(16px + 2vw)">
        {postInfo?.title}
        <hr />
      </Typography>
      <Content dangerouslySetInnerHTML={{ __html: postInfo?.content }} />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Confirm Delete"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this post permanently?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <EditButton onClick={handleClose} autoFocus>
            Cancel
          </EditButton>
          <DeleteButton onClick={deletePost}>Delete</DeleteButton>
        </DialogActions>
      </Dialog>
      <ProductsBar />
    </StyledContainer>
  );
}
