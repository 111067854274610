import { CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Card, CardDeck } from "react-bootstrap";
import { Link } from "react-router-dom";

const cardTextStyle = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  '-webkit-line-clamp': 10, // Limit to 5 lines of text
  '-webkit-box-orient': 'vertical',
  maxHeight: '120px', // Adjust max height as needed to fit your design
};

export const Service = () => {
  const [postsInfo, setPostsInfo] = useState([]);
  const url = process.env.REACT_APP_URL;

  useEffect(() => {
    const ids = [
      "667795f226c744fa511320fc",
      "6677959a26c744fa511320f8",
      "66741b6437bdfe255c21146b"
    ];
    const fetchPosts = async () => {
      for (const id of ids) {
        try {
          const res = await fetch(`${url}/post/${id}`);
          const postInfo = await res.json();
          if(postInfo){
            setPostsInfo((posts) => [...posts, postInfo]);
          }
        } catch (error) {
          console.log("error fetching posts:", error);
        }
      }
    };
    fetchPosts();
  }, [url]);


  if (postsInfo.length===0) {
    return (
      <div style={{ textAlign: "center", marginTop: "50px" }}>
        <CircularProgress />
        <div>Network is slow, please wait...</div>
      </div>
    );
  }

  return (
    <div>
      <CardDeck p-5>
        {postsInfo.map((postInfo, index) => (
          <Card key={index}>
            <Card.Img
              variant="top"
              src={postInfo.cover}
              style={{ height: "200px" }}
            />
            <Card.Body>
              <Card.Title>{postInfo.title}</Card.Title>
              <Card.Text style={cardTextStyle}>{postInfo.summary}</Card.Text>
            </Card.Body>
            <Card.Footer>
              <small className="text-muted">
                <Link to={`/post/${postInfo._id}`}>More information </Link>
              </small>
            </Card.Footer>
          </Card>
        ))}
      </CardDeck>
    </div>
  );
};
