import React from "react";
import { styled, ThemeProvider, createTheme } from '@mui/material/styles';
import { TextField, Button, Grid, Typography, Paper, Box, Container } from '@mui/material';

// Erstellen eines benutzerdefinierten Themes mit der neuen Farbpalette
const theme = createTheme({
  palette: {
    primary: {
      main: '#E63946', // Rot
    },
    secondary: {
      main: '#A8DADC', // Mint Grün
    },
    background: {
      default: '#F1FAEE', // Warmes Weiß
      paper: '#FFFFFF',
    },
    text: {
      primary: '#1D3557', // Tiefes Navy-Schwarz
    },
  },
});

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  backgroundColor: theme.palette.background.paper,
  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
  borderRadius: theme.shape.borderRadius,
}));

const StyledForm = styled('form')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3),
}));

const StyledButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
  backgroundColor: theme.palette.primary.main,
  color: '#FFFFFF',
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  },
}));

const AccentBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.main,
  padding: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
  marginBottom: theme.spacing(3),
}));

export const Contact = () => (
  <ThemeProvider theme={theme}>
    <Box sx={{ py: 4 }}>
      <Container maxWidth="lg">
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Typography variant="h3" gutterBottom color="primary" fontWeight="bold">
              Recipe for You.de
            </Typography>
            <AccentBox>
              <Typography variant="h5" gutterBottom color="text.primary">
                Wir sind für Sie da
              </Typography>
              <Typography variant="body1" color="text.primary">
                Haben Sie Fragen oder Anregungen? Wir freuen uns auf Ihre Nachricht und antworten Ihnen schnellstmöglich.
              </Typography>
            </AccentBox>
          </Grid>
          <Grid item xs={12} md={6}>
            <StyledPaper elevation={3}>
              <StyledForm
                name="contact"
                method="POST"
                data-netlify="true"
                onSubmit={(e) => {
                  e.preventDefault();
                  // Hier Ihre Logik für die Formularübermittlung einfügen
                }}
                netlify-honeypot="bot-field"
              >
                <input type="hidden" name="contact" value="contact" />

                <TextField
                  fullWidth
                  label="Vollständiger Name"
                  name="full-name"
                  variant="outlined"
                  required
                />

                <TextField
                  fullWidth
                  label="E-Mail"
                  name="email"
                  type="email"
                  variant="outlined"
                  required
                />

                <TextField
                  fullWidth
                  label="Telefon"
                  name="phone"
                  type="tel"
                  variant="outlined"
                />

                <TextField
                  fullWidth
                  label="Ihre Nachricht"
                  name="message"
                  multiline
                  rows={4}
                  variant="outlined"
                  required
                />

                <StyledButton variant="contained" type="submit" size="large">
                  Nachricht senden
                </StyledButton>
              </StyledForm>
            </StyledPaper>
          </Grid>
        </Grid>
      </Container>
    </Box>
  </ThemeProvider>
);