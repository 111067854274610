import React, { useState, useEffect } from "react";
import { styled } from "@mui/system";
import {
  Container,
  Grid,
  Card,
  CardContent,
  CardMedia,
  Typography,
  Button,
  Skeleton,
  IconButton,
  Tooltip,
  TextField,
  Box,
  CircularProgress,
  Snackbar,
  Alert,
} from "@mui/material";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

// Styled components
const ProductCard = styled(Card)(({ theme }) => ({
  height: "100%",
  display: "flex",
  flexDirection: "column",
  transition: "transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out",
  "&:hover": {
    transform: "translateY(-5px)",
    boxShadow: theme.shadows[4],
  },
}));

const ProductMedia = styled(CardMedia)(({ theme }) => ({
  height: 0,
  paddingTop: "56.25%",
}));

const ProductContent = styled(CardContent)({
  flexGrow: 1,
});

const ProductName = styled((props) => (
  <Typography variant="h5" component="h2" {...props} />
))(({ theme }) => ({
  fontWeight: "bold",
  marginBottom: "0.5rem",
  fontSize: "1rem",
}));

// const ProductDescription = styled((props) => (
//   <Typography variant="body2" color="text.secondary" {...props} />
// ))(({ theme }) => ({
//   marginBottom: "1rem",
//   fontSize: "0.5rem",
// }));

const IdWrapper = styled(Box)({
  position: "relative",
});
const ProductId = styled(TextField)({});

const CopyId = styled(IconButton)({
  position: "absolute",
  width: "35px",
  height: "35px",
  top: "27px",
  left: "260px",
});

const ActionBox = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  gap: theme.spacing(2),
  marginTop: theme.spacing(2),
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
}));

const StyledButton = styled(Button)(({ theme }) => ({
  flex: 1,
  padding: theme.spacing(1, 2),
}));

const CloseButton = styled(StyledButton)`
  && {
    background-color: #4caf50;
    color: white;

    &:hover {
      background-color: #45a049;
    }
  }
`;

const DeleteButton = styled(StyledButton)`
  && {
    background-color: #f44336;
    color: white;

    &:hover {
      background-color: #d32f2f;
    }
  }
`;

const ProductsList = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [productId, setProductId] = useState("");
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });

  const url = process.env.REACT_APP_URL;

  useEffect(() => {
    const fetchProducts = async () => {
      const response = await fetch(`${url}/products`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
      });
      const data = await response.json();
      setProducts(data);
      setLoading(false);
    };

    fetchProducts();
  }, [url]);

  const handleCopy = (text) => {
    navigator.clipboard.writeText(text);
    alert("Copied to clipboard");
  };

  const deleteProduct = async () => {
    try {
      const response = await fetch(`${url}/products/${productId}`, {
        method: "DELETE",
        headers: { "Content-Type": "application/json" },
        credentials: "include",
      });

      const data = await response.json();
      if (response.ok) {
        setProducts((prevProducts) =>
          prevProducts.filter((product) => product._id !== productId)
        );
        setSnackbar({ open: true, message: data.message, severity: "success" });
      } else {
        setSnackbar({ open: true, message: data.message, severity: "error" });
      }
    } catch (error) {
      console.error("Fetch error:", error);
      setSnackbar({
        open: true,
        message: "An error occurred",
        severity: "error",
      });
    } finally {
      setOpen(false);
    }
  };

  const handleClickOpen = (id) => {
    setProductId(id);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  if (loading) {
    return (
      <div
        style={{
          textAlign: "center",
          marginTop: "50px",
        }}
      >
        <CircularProgress />
        <div>Network is slow, please wait...</div>
      </div>
    );
  }

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  return (
    <Container maxWidth="lg" sx={{ py: 8 }}>
      <Typography variant="h6" component="h6" gutterBottom>
        Associate Product List
      </Typography>
      <Grid container spacing={4}>
        {loading
          ? Array.from(new Array(6)).map((_, index) => (
              <Grid item key={index} xs={12} sm={6} md={4}>
                <ProductCard>
                  <Skeleton
                    variant="rectangular"
                    height={0}
                    sx={{ paddingTop: "56.25%" }}
                  />
                  <ProductContent>
                    <Skeleton variant="text" height={24} width="80%" />
                    <Skeleton variant="text" height={18} width="100%" />
                    <Skeleton variant="text" height={18} width="100%" />
                    <Skeleton
                      variant="rectangular"
                      height={36}
                      width="50%"
                      sx={{ mt: 2 }}
                    />
                  </ProductContent>
                </ProductCard>
              </Grid>
            ))
          : products.map((product) => (
              <Grid item key={product._id} xs={12} sm={6} md={4}>
                <ProductCard>
                  <ProductMedia
                    image={product.imageUrl1}
                    title={product.productName}
                  />
                  <ProductContent>
                    <ProductName>{product.productName}</ProductName>
                    <IdWrapper>
                      <ProductId
                        label="Product ID"
                        value={product._id}
                        InputProps={{
                          readOnly: true,
                        }}
                        variant="outlined"
                        fullWidth
                        margin="normal"
                      />
                      <Tooltip title="Copy ID">
                        <CopyId onClick={() => handleCopy(product._id)}>
                          <FileCopyIcon />
                        </CopyId>
                      </Tooltip>
                    </IdWrapper>
                    <ActionBox>
                      <StyledButton
                        variant="outlined"
                        href={product.associateUrl}
                        target="_blank"
                      >
                        View Product
                      </StyledButton>
                      <StyledButton
                        variant="outlined"
                        color="error"
                        onClick={() => handleClickOpen(product._id)}
                      >
                        Delete
                      </StyledButton>
                    </ActionBox>
                  </ProductContent>
                </ProductCard>
              </Grid>
            ))}
      </Grid>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Use Google's location service?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure to delete this post permanently?!
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CloseButton onClick={handleClose} autoFocus>
            Disagree
          </CloseButton>
          <DeleteButton onClick={deleteProduct}>Agree</DeleteButton>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbar.severity}
          sx={{ width: "100%" }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default ProductsList;
