//display associate products in bottom of page
import React, { useState, useEffect } from "react";
import { styled } from "@mui/system";
import {
  Container,
  Grid,
  Card,
  CardContent,
  CardMedia,
  Typography,
  Button,
  CircularProgress,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

const StyledButton = styled(Button)(({ theme }) => ({
  padding: theme.spacing(1, 2),
  fontSize: "11px",
}));



const ProductsBar = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const url = process.env.REACT_APP_URL;
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProducts = async () => {
      const response = await fetch(`${url}/products`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
      });
      const data = await response.json();
      setProducts(data);
      setLoading(false);
    };

    fetchProducts();
  }, [url]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % 4);
    }, 3000);

    return () => clearInterval(intervalId);
  }, []);

  if (loading) {
    return (
      <div style={{ textAlign: "center", marginTop: "50px" }}>
        <CircularProgress />
        <div>Network is slow, please wait...</div>
      </div>
    );
  }

  const handleClick = (id, e) => {
    e.preventDefault(); // Prevent default anchor behavior
    e.stopPropagation(); // Prevent propagation to the parent card
    navigate(`/associate/${id}`); // Navigate within the app
  };

  return (
    <Container maxWidth="lg" sx={{ py: 8 }}>
      <Typography variant="h6" component="h6" gutterBottom>
        Produkte im Amazon Store finden
      </Typography>
      <Grid container spacing={4}>
        {products.map((product) => (
          <Grid item key={product._id} xs={12} sm={6} md={4}>
            <Card
              sx={{ maxHeight: 400, cursor: "pointer" }}
              onClick={() =>
                window.open(
                  product.associateUrl,
                  "_blank",
                  "noopener,noreferrer"
                )
              }
            >
              <CardMedia
                image={product[`imageUrl${(currentImageIndex % 4) + 1}`]}
                title={product.productName}
                style={{ height: 0, paddingTop: "56.25%" }}
                sx={{ fontSize: `calc(16px+1vw)` }}
              />
              <CardContent
                sx={{
                  height: "200px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    fontSize: "calc(12px + 0.25vw)",
                    display: "-webkit-box",
                    WebkitBoxOrient: "vertical",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    WebkitLineClamp: 3, // Limits to 3 lines
                    lineHeight: 1.5,
                    maxHeight: "4.5em", // This value should match 3 lines of text
                  }}
                >
                  {product.productName}
                </Typography>
                <StyledButton
                  variant="outlined"
                  href={product.associateUrl}
                  target="_blank"
                  onClick={(e) => handleClick(product._id, e)}
                >
                  Amazon Product Details
                </StyledButton>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default ProductsBar;
